<script>
  const { config } = $$props;
</script>

<style>
  .privacy-consent--heading {
    font-family: "Overpass Bold",Arial,sans-serif;
    color: #001b41;
    display: block;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 32px !important;
    margin-bottom: 2rem !important;
  }

  @media screen and (min-width: 840px) {
    .privacy-consent--heading {
      font-size: 28px;
      line-height: 32px;
    }
    .privacy-consent--heading.test {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px !important;
    }
  }

  @media screen and (max-height: 820px) {
    .privacy-consent--heading {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px !important;
      margin-bottom: 1rem !important;
    }
  }
</style>

<h3 class="privacy-consent--heading {config.alternativeDisplay === true ? 'test' : ''}">
  <slot />
</h3>
