<style>
  .privacy-consent--col {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 24px;
    padding-left: 1.5rem;
    padding-right: 24px;
    padding-right: 1.5rem;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  @media screen and (min-width: 1025px) {
    .privacy-consent--col {
      -ms-flex-preferred-size: 0;
      -webkit-flex-basis: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      max-width: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
  }
</style>

<div {...$$props} class="privacy-consent--col">
  <slot />
</div>
