<style>
  .privacy-consent--row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -24px;
    margin: 0 -1.5rem;
  }

  @media screen and (min-width: 850px) {
    .justify-center {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      -webkit-align-items: center !important;
      align-items: center !important;
      -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
      -webkit-justify-content: center !important;
      justify-content: center !important;
    }

    .justify-between {
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      -webkit-align-items: center !important;
      align-items: center !important;
      -webkit-box-pack: space-between !important;
      -ms-flex-pack: space-between !important;
      -webkit-justify-content: space-between !important;
      justify-content: space-between !important;
    }

    .justify-end {
      -webkit-box-align: end !important;
      -ms-flex-align: end !important;
      -webkit-align-items: flex-end !important;
      align-items: end !important;
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      -webkit-justify-content: flex-end !important;
      justify-content: flex-end !important;
    }

    .row-reverse {
      -webkit-box-orient: horizontal !important;
      -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
      -webkit-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
  }
  @media screen and (min-width: 451px) and (max-width: 850px) {
    .privacy-consent--row.justify-end.test {
      justify-content: center;
    }
  }
</style>

<div class="privacy-consent--row {$$props.class}">
  <slot />
</div>
