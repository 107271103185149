<script>
  const { onClick, id } = $$props;
</script>

<style>
  .footer-config-link {
    color: grey !important;
    text-decoration: none !important;
    padding-top: 10px;
  }

  @media screen and (min-width: 850px) {
    .footer-config-link {
      min-width: 210px;
    }
  }
</style>

<button class="link {$$props.class}" id="{id}" on:click={onClick}>
  <slot />
</button>