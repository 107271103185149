<script>
  export const config = $$props.config;
</script>
<style>
  .privacy-consent--button-container {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 24px;
    padding-right: 24px;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 450px) {
    .privacy-consent--button-container + .privacy-consent--button-container {
      margin-top: 0;
      margin-bottom: 12px;
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      -webkit-order: -1;
      order: -1;
    }
    .privacy-consent--button-container:nth-of-type(1) {
      order: -1;
    }
    .privacy-consent--button-container:nth-of-type(2) {
      order: 1;
      margin-top: 16px;
    }
    .privacy-consent--button-container.spain:nth-of-type(2) {
      text-align: center;
    }
    .privacy-consent--button-container:nth-of-type(3) {
      margin-bottom: 0;
      margin-top: 16px;
    }
  }

  @media screen and (min-height: 851px) {
    .privacy-consent--button-container + .privacy-consent--button-container {
      margin-top: 16px;
    }
  }

  @media screen and (min-width: 550px) and (max-width: 850px) {
    .privacy-consent--button-container {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      -webkit-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .privacy-consent--button-container.spain:nth-of-type(2) {
      order: 1;
      text-align: center;
    }

    .privacy-consent--button-container + .privacy-consent--button-container {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 451px) and (max-width: 850px) {
    .privacy-consent--button-container.test {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 80%;
      -webkit-flex: 0 0 80%;
      flex: 0 0 80%;
      max-width: 60%;
    }

    .privacy-consent--button-container{
      margin-bottom: 16px;
    }
  }

  @media screen and (min-width: 850px) {
    .privacy-consent--button-container + .privacy-consent--button-container {
      margin-top: 0;
      padding-left: 0;
      -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
      -webkit-order: -1;
      order: -1;
    }

    .privacy-consent--button-container {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-left: 24px;
      padding-right: 24px;
      -ms-flex-preferred-size: 0;
      -webkit-flex-basis: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      max-width: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .privacy-consent--button-container:nth-of-type(1) {
      padding-left: 0px;
      padding-right: 20px;
      margin-top: 16px;
    }
  }
</style>

<div class="privacy-consent--button-container {config.alternativeDisplay === true ? 'test' : ''} {config.alternativeDisplaySpain === true ? 'spain' : ''}">
  <slot />
</div>