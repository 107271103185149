<script>
  import ButtonLink from '../dumb-components/ButtonLink.svelte';
  import Button from '../dumb-components/Button.svelte';
  import Link from '../dumb-components/Link.svelte';
  import Text from '../dumb-components/Text.svelte';

  const { config, description, onReject, onGoToConfig, onSelectAll } = $$props;

  const link = {
     REJECT_BUTTON: {
       id: 'confirmSelection',
       label: config.rejectButton,
       onClick: onReject
     },
     CONFIG_BUTTON: {
       id: 'configButton',
       label: config.configButton,
       onClick: onGoToConfig
     },
     CONFIG_BUTTON_TEXT: {
       id: 'configButtonText',
       label: config.configButtonText,
       onClick: onGoToConfig
     },
     SELECTALL_BUTTON: {
       id: 'selectAll',
       label: config.selectAll,
       onClick: onSelectAll
     },
  };
</script>

<style>
  p + p {
    margin-top: 10px;
  }
  p {
    margin-top: 10px !important;
  }
  .tooltip {
    background-image: url("data:image/svg+xml,%3Csvg id='ICON' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B opacity: 0; %7D .cls-2 %7B fill: %23fff; %7D .cls-3 %7B fill: %230b9dcc; %7D %3C/style%3E%3C/defs%3E%3Cg id='ic-s24-regular-circle-information-mark'%3E%3Cg id='background' class='cls-1'%3E%3Cpath class='cls-2' d='M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Z'/%3E%3C/g%3E%3Cpath id='content' class='cls-3' d='M13.125,8A1.125,1.125,0,1,1,12,6.875,1.125,1.125,0,0,1,13.125,8ZM22,12A10,10,0,1,1,12,2,10.011,10.011,0,0,1,22,12Zm-2,0a8,8,0,1,0-8,8A8.009,8.009,0,0,0,20,12Zm-8-2a1,1,0,0,0-1,1v5a1,1,0,0,0,2,0V11A1,1,0,0,0,12,10Z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 3px;
    margin-left: 3px;
    margin-bottom: -4px;
  }
  .tooltip:hover:after {
    content: attr(data-text);
    position:absolute;
    transform:translate(-50%,-103%);
    padding:7px;
    background:#fff;
    color: #465a75;
    -webkit-box-shadow: 0 1px 3px rgba(0,0,0,.03),0 1px 2px rgba(0,0,0,.18);
    box-shadow: 0 1px 3px rgba(0,0,0,.03),0 1px 2px rgba(0,0,0,.18);
    width: 300px;
  }
  @media (max-width: 580px) {
    .tooltip:after {
      left: 10px;
      right: 10px;
    }
    .tooltip:hover:after {
      transform:translate(0,-103%) !important;
      width: auto;
    }
  }
</style>

<Text class="{$$props.class}">
  {#if config.advancedPageFlow === false && config.acceptDescription}
    {#each description as element}
      {#if element === 'SELECTALL_BUTTON'}
        <ButtonLink id="selectAll" onClick={onSelectAll}>{link[element].label}</ButtonLink>
      {:else if element === 'TOOLTIP'}
        <span class="tooltip" data-text="{config.thirdCountryData}"></span>
      {:else}
        {@html element}
      {/if}
    {/each}
  {:else}
    {#each description as p}
      <p>
        {#each p as element}
          {#if element === 'REJECT_BUTTON' || element === 'CONFIG_BUTTON' || element === 'CONFIG_BUTTON_TEXT' || element === 'SELECTALL_BUTTON'}
            <ButtonLink id="{link[element].id}" onClick={link[element].onClick}>{link[element].label}</ButtonLink>
          {:else if element === 'LINK_PRIVACY_TERMS'}
            <Link href="{config.siteConfig.privacyTermsUrl}">{config.privacyTermsLink}</Link>
          {:else if element === 'LINK_COOKIE_TERMS'}
            <Link href="{config.siteConfig.cookiePageUrl}">{config.cookieTermsLink}</Link>
          {:else if element === 'LINK_COOKIE_PAGE'}
            <Link href="{config.siteConfig.cookiePageUrl}">{config.cookiePageLink}</Link>
          {:else if element === 'TOOLTIP'}
            <span class="tooltip" data-text="{config.thirdCountryData}"></span>
          {:else}
            {@html element}
          {/if}
        {/each}
      </p>
    {/each}
  {/if}
</Text>