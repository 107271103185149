<style>
  .privacy-consent--text + .privacy-consent--text {
    padding-top: 16px;
  }

  @media screen and (max-height: 820px) {
    .privacy-consent--text {
      font-size: 12px;
    }
  }

  @media screen and (max-height: 820px) {
    .smart-height  {
      overflow: auto;
    }
  }

  @media screen and (max-height: 580px) {
    .smart-height {
      max-height: 100px;
    }
  }

  @media screen and (min-height: 581px) and (max-height: 680px) {
    .smart-height {
      max-height: 180px;
    }
  }

  @media screen and (min-height: 681px) and (max-height: 780px) {
    .smart-height {
      max-height: 240px;
    }
  }

  @media screen and (min-height: 781px) and (max-height: 820px) {
    .smart-height {
      max-height: 320px;
    }
  }
  @media screen and (min-width: 933px) {
    .privacy-consent--text.test {
      font-size: 12px;
    }
  }
</style>

<p class="privacy-consent--text {$$props.class}">
  <slot />
</p>