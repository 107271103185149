<script>
  import Text from "./Text.svelte";
  import Table from "./Table.svelte";
  import TableCookies from "./TableCookies.svelte";

  const { details } = $$props;
</script>

<style>
  .privacy-consent--details {
    padding: 32px 24px;
  }

  @media screen and (min-width: 850px) {
    .privacy-consent--details {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
      padding-bottom: 0;
    }
  }

  @media screen and (max-height: 450px) {
    .privacy-consent--details {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      -webkit-order: 2;
      order: 2;
      padding-bottom: 0;
    }
  }

  @media screen and (max-height: 850px) {
    .privacy-consent--details {
      padding: 16px 24px;
    }
  }
</style>

<div class="privacy-consent--details">
  {#each details as detail}
    {#if detail.type === 'text'}
      <Text>{detail.value}</Text>
    {:else if detail.type === 'table'}
      <Table data={detail.value} />
    {:else if detail.type === 'cookietable'}
      <TableCookies data={detail.value} />
    {/if}
  {/each}
</div>