<style>
  .privacy-consent--backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    -webkit-transition: all ease .125s;
    -o-transition: all ease .125s;
    transition: all ease .125s;
    z-index: 1049;
    background: rgba(0, 61, 143, 0.4);
  }
</style>

<div class="privacy-consent--backdrop">
  <slot />
</div>
