<script>
  import Backdrop from "./Backdrop.svelte";
  export const config = $$props.config;
</script>

<style>
  .privacy-consent--modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    -webkit-overflow-scrolling: touch;
    font-size: 16px;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    backface-visibility: hidden;
  }

  .privacy-consent--modal-dialog {
    z-index: 1;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    right: 0;
    top: 32px;
    padding-bottom: 32px;
    width: 100%;
    max-width: 90vw;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  @media screen and (min-width: 933px) {
    .privacy-consent--modal-dialog {
      max-width: 840px;
      right: 10%;
      bottom: 10%;
    }
    .privacy-consent--modal.test .privacy-consent--modal-content {
      flex-direction: row;
      border-radius: 6px;
    }
    .privacy-consent--modal.test .privacy-consent--modal-dialog {
      max-width: 99%;
      left: .5%;
      right: 0px;
      top: unset;
      bottom: 1%;
      padding-bottom: 0;
      -webkit-transform: unset;
      -ms-transform: unset;
      transform: unset;
    }
    :global(.privacy-consent--modal.test .privacy-consent--modal-content a),
    :global(.privacy-consent--modal.test .privacy-consent--modal-content .link) {
      font-size: 12px;
    }
    .privacy-consent--modal.test .privacy-consent--imprint {
      text-align: right;
    }
  }

  @media screen and (max-width: 900px) {
    .privacy-consent--modal-dialog {
      height: 95%;
      bottom: 32px;
      padding-bottom: 0;
      top: 16px;
    }
  }

  .privacy-consent--modal-content {
    position: relative;
    background: #fff;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    box-shadow: 0 2px 5px 0 rgba(0,0,0,.2);
    color: #465a75;
    font-family: "Open Sans Regular", Arial, sans-serif;
    line-height: 24px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-height: 820px) {
    .privacy-consent--modal-content {
      font-size: 12px;
    }
  }

  :global(.privacy-consent--modal-content a),
  :global(.privacy-consent--modal-content .link) {
    color: #0b9dcc;
    cursor: pointer;
    text-decoration: none;
    word-wrap: break-word;
    border: none;
    background: none !important;
    font-size: 14px;
  }

  :global(.privacy-consent--modal-content a:hover),
  :global(.privacy-consent--modal-content .link:hover) {
    text-decoration: underline;
  }

  @media screen and (max-height: 820px) {
    :global(.privacy-consent--modal-content a),
    :global(.privacy-consent--modal-content .link) {
      font-size: 12px;
    }
  }

</style>

{#if $$props.show}
  <Backdrop />
  {#if config.alternativeDisplay === true}
    <div class="privacy-consent--modal test">
      <div class="privacy-consent--modal-dialog">
        <div class="privacy-consent--modal-content">
          <slot />
        </div>
      </div>
    </div>
  {:else}
    <div class="privacy-consent--modal">
      <div class="privacy-consent--modal-dialog">
        <div class="privacy-consent--modal-content">
          <slot />
        </div>
      </div>
    </div>
  {/if}
{/if}
