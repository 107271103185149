<script>
  const { id, buttonClass, onClick } = $$props;
</script>

<style>
  .privacy-consent--button {
    background: transparent;
    border: 2px solid #0b9dcc;
    -webkit-border-radius: 3px;
            border-radius: 3px;
    color: #0b9dcc;
    padding: 8px 16px;
    margin: 0;
    text-align: center;
    text-decoration: none;
    display: block;
    font-size: 16px;
    line-height: 24px;
    -webkit-transition: color 0.1s ease-out, background-color 0.1s ease-out, border-color 0.1s ease-out;
    transition: color 0.1s ease-out, background-color 0.1s ease-out, border-color 0.1s ease-out;
    cursor: pointer;
    width: 100%;
    font-family: 'Open Sans Bold', Arial, sans-serif;
  }
  .privacy-consent--button:hover {
    background: #28cce8;
    border-color: #28cce8;
    color: #fff;
    text-decoration: none !important;
  }
  .privacy-consent--button:active {
    background: #0b3476;
    color: #fff;
    border-color: #0b3476;
  }

  @media screen and (max-width: 850px) {
    .privacy-consent--button {
      font-size: 12px;
    }
    .privacy-consent--button.first {
      order: 0;
    }
  }

  @media screen and (min-width: 850px) {
    .privacy-consent--button {
      min-width: 210px;
    }
  }

  .privacy-consent--button.privacy-consent--button-primary {
    background: #0b9dcc;
    color: #fff;
    border-color: #0b9dcc;
    border-radius: 60px !important;
  }
  .privacy-consent--button.privacy-consent--button-primary.test {
    background: #ffffff !important;
    border-color: #0b9dcc !important;
    color: #0b9dcc !important;
    border-radius: 60px !important;
    min-width: 175px;
  }
  .privacy-consent--button.privacy-consent--button-primary.test:hover {
    background: #ffffff !important;
    border-color: #0b9dcc !important;
    color: #0b9dcc !important;
  }
  .privacy-consent--button.privacy-consent--button-primary:hover {
    background: #28cce8;
    border-color: #28cce8;
    color: #fff;
    text-decoration: none !important;
  }
  .privacy-consent--button.privacy-consent--button-primary:active {
    background: #0674b2;
    border-color: #0674b2;
    color: #fff;
  }

  .privacy-consent--button-back {
    border: none;
    display: flex;
    justify-content: center;
    min-width: 150px !important;
  }
  .privacy-consent--button-back:hover {
    color: #0b9dcc;
    background: none;
    text-decoration: underline !important;
  }
</style>

<button class="privacy-consent--button {buttonClass}" id="{id}" on:click={onClick}>
  <slot />
</button>