<script>
  const { config } = $$props;

</script>

<style>
  .privacy-consent--imprint  {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 24px;
    padding-right: 24px;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    font-size: 16px;
  }

  @media screen and (max-width: 850px) {
    .privacy-consent--imprint {
      margin-top: 12px;
    }
  }

  @media screen and (min-height: 851px) {
    .privacy-consent--imprint {
      margin-top: 16px;
    }
  }

  @media screen and (min-width: 850px) {
    .privacy-consent--imprint {
      -ms-flex-preferred-size: 0;
      -webkit-flex-basis: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      max-width: 100%;
      margin-top: 0;
    }
    .privacy-consent--imprint.test {
      -webkit-box-flex: 0;
      -ms-flex-positive: 0;
      -webkit-flex-grow: 0;
      flex-grow: 0;
      margin-top: 8px;
    }
  }
</style>
{#if config.alternativeDisplay === true}
  <div class="privacy-consent--imprint test">
    <a href={config.imprint.href}>{config.imprint.label}</a>
  </div>
{:else}
  <div class="privacy-consent--imprint">
    <a href={config.imprint.href}>{config.imprint.label}</a>
  </div>
{/if}