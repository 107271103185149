<script>
  const { config } = $$props;
</script>

<style>
  .privacy-consent--foot {
    padding: 0 32px 32px 32px;
    flex-grow: 1;
  }
  @media screen and (min-width: 933px) {
    .privacy-consent--foot.flex {
      display: flex;
      width: auto;
      -webkit-box-align: center !important;
      -ms-flex-align: center !important;
      -webkit-align-items: center !important;
      align-items: center !important;
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      -webkit-justify-content: flex-end !important;
      justify-content: flex-end !important;
      padding: 0 32px 0 0;
    }
  }
</style>

<div class="privacy-consent--foot {config.alternativeDisplay === true ? 'flex' : ''}">
  <slot />
</div>