<script>
  const { config } = $$props;
</script>

<style>
  .back-arrow {
    width: 1.5em;
    height: 1.5em;
    margin-right: 10px;
    background: url(https://ce2.uicdn.net/7a8/b8071ed785279c261181253887a3d/frontend-applications-common/ic-arrow-round-left.svg) no-repeat top left;
  }
</style>

<span class="back-arrow" alt="{config.goBack}"></span>