<script>
  const { config } = $$props;
</script>

<style>
  .privacy-consent--body {
    padding: 32px;
    flex-grow: 1;
  }
  @media screen and (min-width: 933px) {
    .flex {
      width: 50%;
      padding: 20px 28px 20px 28px !important;
    }
  }
  @media screen and (max-height: 820px) {
    .privacy-consent--body {
      padding: 28px 28px 20px 28px;
    }
  }

  .privacy-consent--body label,
   .privacy-consent--body span  {
    font-size: 12px !important;
  }
</style>

<div class="privacy-consent--body {config.alternativeDisplay === true ? 'flex' : ''}">
  <slot />
</div>