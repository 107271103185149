<script>
  import Body from "../dumb-components/Body.svelte";
  import Intro from "../dumb-components/Intro.svelte";
  import TextAdvanced from "../dumb-components/TextAdvanced.svelte";
  import Heading from "../dumb-components/Heading.svelte";
  import Footer from "../dumb-components/Footer.svelte";
  import ButtonContainer from '../dumb-components/ButtonContainer.svelte';
  import Button from '../dumb-components/Button.svelte';
  import ButtonLink from '../dumb-components/ButtonLink.svelte';
  import Row from '../dumb-components/Row.svelte';
  import Imprint from '../dumb-components/Imprint.svelte';
  import Link from '../dumb-components/Link.svelte';

  const { config, onSelectAll, onReject, onGoToConfig } = $$props;
</script>

<style>
  .privacy-consent--textlink  {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-left: 14px;
    padding-right: 14px;
    -ms-flex-preferred-size: 0;
    -webkit-flex-basis: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    -webkit-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  @media screen and (max-width: 850px) {
    .privacy-consent--textlink {
      margin-top: 12px;
    }
  }

  @media screen and (min-height: 851px) {
    .privacy-consent--textlink {
      margin-top: 16px;
    }
  }

  @media screen and (min-width: 850px) {
    .privacy-consent--textlink {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-left: 14px;
      padding-right: 14px;
      -ms-flex-preferred-size: 0;
      -webkit-flex-basis: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      -webkit-flex-grow: 1;
      flex-grow: 1;
      max-width: 100%;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
      margin-top: 16px;
    }
  }
</style>

<Body {config}>
  <Heading {config}>{config.heading}</Heading>

  <Intro {config}>
    <TextAdvanced {config} class="{config.alternativeDisplay === true ? 'test' : ''}" description="{config.step1Description}" {onReject} {onGoToConfig} {onSelectAll} />
  </Intro>
  <Row class="justify-start">
    <Imprint {config} />
  </Row>
</Body>

<Footer {config}>
  {#if config.alternativeDisplay === true}
    <Row class="justify-end test">
      <ButtonContainer {config}>
        <Button buttonClass="privacy-consent--button-primary {config.alternativeDisplaySpain !== true ? 'test' : ''}" id="selectAll" onClick={onSelectAll}>
          {config.selectAll}
        </Button>
      </ButtonContainer>

      <ButtonContainer {config}>
        {#if config.alternativeDisplaySpain === true}
          <Button buttonClass="privacy-consent--button-back" id="confirmSelection" onClick={onReject}>
             {config.confirmFunctional}
          </Button>
        {:else}
          <Button buttonClass="privacy-consent--button-primary test" id="confirmSelection" onClick={onReject}>
            {config.confirmFunctional}
          </Button>
        {/if}
      </ButtonContainer>

      <ButtonContainer {config}>
        <Button buttonClass="privacy-consent--button-primary test" onClick={onGoToConfig}>
          {config.configButton}
        </Button>
      </ButtonContainer>
    </Row>
  {:else}
    <Row class="justify-center row-reverse">
      <ButtonContainer {config}>
        <Button buttonClass="privacy-consent--button-primary" id="selectAll" onClick={onSelectAll}>
          {config.selectAll}
        </Button>
      </ButtonContainer>

      <Imprint {config} />
    </Row>

    <Row class="justify-end">
      <ButtonContainer {config}>
        <ButtonLink class="footer-config-link" onClick={onGoToConfig}>
          {config.configButton}
        </ButtonLink>
      </ButtonContainer>
    </Row>
  {/if}
</Footer>