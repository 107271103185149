<script>
  const { config } = $$props;
</script>

<style>
  .privacy-consent--intro {
    padding-bottom: 32px;
  }
  .privacy-consent--intro.test {
    padding-bottom: 0px !important;
    line-height: 1rem;
  }

  @media screen and (max-width: 850px) {
    .privacy-consent--intro {
      padding-bottom: 12px;
    }
  }

  @media screen and (max-height: 820px) {
    .privacy-consent--intro {
      padding-bottom: 12px;
    }
  }
</style>

<div class="privacy-consent--intro {config.alternativeDisplay === true ? 'test' : ''}">
  <slot />
</div>