<script>
  const head = $$props.data[0];
  const body = $$props.data.slice(1);
</script>

<style>
  .privacy-consent--table {
    padding-top: 32px;
    overflow-y: scroll;
    max-height:400px;
  }

  .privacy-consent--table div.head {
    background-color: rgb(246, 247, 248);
    border: solid 1px #DFE2E7;
    text-align: left;
    font-size: 14px;
    font-weight: normal;
    color: black;
    padding: 14px;
  }

  .privacy-consent--table div.head:first-of-type,
  .privacy-consent--table .flex-item:nth-child(odd) {
    width: 30%;
  }
  .privacy-consent--table div.head:last-of-type,
  .privacy-consent--table .flex-item:nth-child(even) {
    width: 70%;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    text-align: left;
    border-left: solid 1px #DFE2E7;
  }

  .flex-item {
    font-size: 12px;
    color: #223857;
    border-style: solid;
    border-color: #DFE2E7;
    border-width:0 1px 1px 0;
    background-color: #fff;
    padding: 14px;
    width: 33.33%;
  }
  @media (max-width: 580px) {
    .privacy-consent--table {
      padding-top: 18px;
    }
    .flex-item {
      width: 50%;
    }
  }
</style>

<div class="privacy-consent--table">

  <div class="flex-container">
    {#each head as col}
      <div class="head">{col}</div>
    {/each}
  </div>

  <div class="flex-container">
    {#each body as element}
      {#each element as row}
        {#each row as col}
          <div class="flex-item">
            {col}
          </div>
        {/each}
      {/each}
    {/each}
  </div>
</div>
