<script>
  import Modal from "./dumb-components/Modal.svelte";
  import Form from "./smart-components/Form.svelte";

  export const config = $$props.config;
  export const consent = $$props.consent;
  export const ref = $$props.ref;
  export let show = $$props.show;

  const onSubmit = ({ detail }) => {
    $$props.ref.__call("statusChanged", detail.consent, detail.buttonId);
  };

  const onClick = ({ detail }) => {
    $$props.ref.__call("onButtonClicked", detail.buttonId);
  };
</script>

<style>
  :global(.privacy-consent--modal *) {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  :global(.privacy-consent--active) {
    overflow: hidden;
  }
</style>

<Modal {show} {config}>
  <Form on:submit={onSubmit} on:click={onClick} {config} {consent} {ref} />
</Modal>
